import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import { useDebounce } from "../../utils/hooks"

import Results from "./Results"
import findUsers from "../../bssr-api/auth0/find-users"
import { TextField } from "../inputs"
import { Button } from "../button"

function UserLookup({ fieldKey, label, onSubmit, isLoading, references }) {
  const [searchTerm, setSearchTerm] = useState("")
  const [results, setResults] = useState([])
  const [haltChange, setHaltChange] = useState(false)
  const [hideResults, setHideResults] = useState(false)
  const [isSearching, setIsSearching] = useState(false)

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  // Setup Formik
  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      selectedUser: {},
    },
    onSubmit: (values) => {
      const { selectedUser } = values
      onSubmit(selectedUser)
      formik.resetForm()
    },
  })

  useEffect(() => {
    async function getData() {
      if (debouncedSearchTerm) {
        if (hideResults) {
          setHideResults(false)
        }

        setIsSearching(true)

        const users = await findUsers(debouncedSearchTerm)

        // We need to return users that are not "free" or "lapsed"
        // As well as users who have not already been selected
        if (users && users?.data?.length > 0) {
          const newResults = users.data
            .map((user) => {
              return {
                id: user.user_id,
                name: `${user.given_name} ${user.family_name}`,
                email: user.email,
                account_type: user.account_type || "free",
                role: user.role || "free",
              }
            })
            .filter((user) => {
              return (
                user.account_type !== "free" &&
                user.account_type !== "lapsed" &&
                !references.find((ref) => ref.referee_id === user.id)
              )
            })

          setResults(newResults)
          setIsSearching(false)
        } else {
          setResults([])
          setIsSearching(false)
        }
      } else {
        setResults([])
        setIsSearching(false)
      }
    }

    if (!haltChange) {
      getData()
    }
  }, [debouncedSearchTerm])

  /**
   * Handle Selection Method
   * Sets the payload object for the selected user
   */
  function handleSelection(user) {
    setHaltChange(true)
    setSearchTerm(user.name)

    // Assign value in formik
    formik.setFieldValue("selectedUser", user)

    setHideResults(true)

    setTimeout(() => {
      setResults([])
      setHaltChange(false)
    }, 1500)
  }

  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        className="flex mb-2 space-between items-center"
      >
        <div className="flex-1 pr-4">
          <label
            htmlFor={`${fieldKey}-reference`}
            className="block text-gray-700"
          >
            {label}
          </label>
          <TextField
            id={`${fieldKey}-reference`}
            name={`${fieldKey}-reference`}
            type="text"
            placeholder="Enter a name or email address"
            className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
            onKeyUp={(e) => setSearchTerm(e.target.value)}
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
            loading={isSearching}
          />
        </div>
        <div style={{ paddingTop: "2rem" }}>
          <Button
            disabled={!(Object.keys(formik.values.selectedUser).length > 0)}
            type="submit"
            className="w-full"
            isLoading={isLoading}
          >
            Request Referral
          </Button>
        </div>
      </form>
      {searchTerm !== "" && !hideResults && (
        <Results
          onSelect={handleSelection}
          results={results}
          loading={isSearching}
        />
      )}
    </>
  )
}

export default UserLookup
