import React from "react"
import PropTypes from "prop-types"

export default function TableBody({ rows }) {
  return (
    <tbody className="bg-white">
      {rows.map((row, index) => {
        const Component = row.cell
        return (
          <tr key={index}>
            <Component />
          </tr>
        )
      })}
    </tbody>
  )
}

TableBody.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}
