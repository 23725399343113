import * as React from "react"
import { useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { debounce } from "lodash"
import { useAuth } from "../providers/auth/AuthProvider"
import ClipLoader from "react-spinners/ClipLoader"
import Layout from "../components/layout"
import Navigation from "../components/account/pages/Navigation"
import PageLoader from "../components/page-loader"
import Seo from "../components/seo"
import UserTable from "../components/user-table"
import getUserRecord from "../bssr-api/auth0/get-user"
import { navigate } from "@reach/router"

export const SearchConnect = () => {
  const { getIdTokenClaims } = useAuth0()
  const [loading, setLoading] = useState(true)
  const [state, dispatch] = useAuth()
  const [term, setTerm] = useState("")
  const [users, setUsers] = useState([])

  console.log("users", users)

  useEffect(() => {
    // Check window?
    if (window.__LUNR__) {
      window.__LUNR__.__loaded.then((lunr) => {
        // Lunr is loaded...
        let query = term

        if (term !== "") {
          query = `${term}^100 ${term}*^10 ${term}~2`
        }

        let refs = lunr.en.index.search(query)

        const scored = refs
        const users = scored.map(({ ref }) => lunr.en.store[ref])

        setUsers(users)
        setLoading(false)
      })
    }
  }, [term])

  const handleFilter = (event) => {
    event.persist()

    const debounced = debounce(() => {
      const term = event.target.value
      setTerm(term)
    }, 1500)

    debounced()
  }

  useEffect(() => {
    getIdTokenClaims()
      .then((res) => {
        if (Object.keys(state).length === 0 && typeof window !== "undefined") {
          const idToken = res.__raw

          getUserRecord(res.sub, idToken)
            .then((res) => {
              if (res.data) {
                dispatch({ type: "setUser", payload: { ...res.data, idToken } })
                setLoading(false)
              } else {
                console.error("getUserData() issue with res", res)
              }
            })
            .catch((err) => {
              console.error("getUserData() error", err)
            })
        } else {
          setLoading(false)
        }
      })
      .catch((err) => console.error("err", err))
  }, [])

  if (loading) {
    return (
      <Layout>
        <Seo title="Search and Connect" />
        <PageLoader />
      </Layout>
    )
  }

  if (state.app_metadata.is_overseas) {
    return navigate("/account")
  }

  return (
    <Layout>
      <Seo title="Search and Connect" />
      <div className="container mx-auto px-1 py-3 lg:py-4 lg:flex items-start justify-end">
        {state.user_id && (
          <Navigation
            isEmailVerified={state.email_verified}
            accountStatus={{
              current_period_start: state.app_metadata.current_period_start,
              type: state.app_metadata.account_type,
              role: state.app_metadata.role,
              is_overseas: state.app_metadata.is_overseas,
            }}
          />
        )}
      </div>

      <section
        id="search-members"
        className="w-full pb-32 bg-indigo-900 border-t-8 border-gray-400"
      >
        <form
          className="max-w-2xl mx-auto py-3 px-5 mt-8"
          onSubmit={handleFilter}
        >
          <label
            htmlFor="search_term"
            className="block text-center md:text-lg lg:text-2xl ml-2 font-medium text-gray-700"
          >
            <h2 className="max-w-2xl serif text-gray-200 text-xl lg:text-5xl font-bold mt-3">
              Search and connect
            </h2>
            <span className="block text-sm font-normal text-indigo-200 pt-1">
              Start typing a name or email address to filter the directory of
              members
            </span>
          </label>
          <input
            type="text"
            name="search_term"
            id="search_term"
            placeholder="Who do you want to find?"
            className="w-full px-4 py-3 mt-3 rounded-lg bg-gray-200 mt-2 border-2 focus:border-blue-500 focus:bg-white focus:outline-none"
            autoComplete="true"
            required
            onChange={handleFilter}
          />
        </form>
      </section>

      <div className="container mx-auto">
        <div className="mt-12 flex flex-col">
          <div className="py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 -mt-24">
            <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200 bg-white">
              {loading ? (
                <div className="flex justify-center align-center py-8">
                  <ClipLoader size={24} color="#3c366b" />
                </div>
              ) : (
                <UserTable data={users} user={state} />
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
