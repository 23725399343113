import React, { useState, useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"

import { Button } from "../button"
import Select from "../inputs/select"

import updateUserRecord from "../../bssr-api/auth0/update-user"

const options = [
  { label: "Free", value: "free" },
  { label: "Full Member", value: "full" },
  { label: "Associate", value: "associate" },
  { label: "Overseas", value: "overseas" },
  { label: "Lapsed", value: "lapsed" },
  { label: "Retired/Honorary", value: "retired" },
  { label: "Exec", value: "exec" },
  { label: "Admin", value: "admin" },
]

function AccountToggle({ userId, accountType, token }) {
  const [value, setValue] = useState(undefined)
  const [complete, setCompleteFlag] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { getIdTokenClaims } = useAuth0()

  useEffect(() => {
    setValue(accountType)
  }, [])

  function handleChange(e) {
    setValue(e.target.value)
  }

  async function handleUpdate(e) {
    setIsLoading(true)
    e.preventDefault()

    const payload = {
      app_metadata: {
        account_type: value,
      },
    }

    const claims = await getIdTokenClaims()
    const _token = claims.__raw

    await updateUserRecord(userId, { ...payload }, true, _token)

    setCompleteFlag(true)
    setIsLoading(false)
  }

  return (
    <>
      <form className="flex items-center" onSubmit={handleUpdate}>
        <div className="mr-4 w-4/6 text-sm">
          <Select
            id="status"
            name="status"
            value={value}
            onChange={handleChange}
          >
            {options.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              )
            })}
          </Select>
        </div>

        <div className="mr-4 w-2/6 text-sm">
          <Button isLoading={isLoading} size="medium" type="submit">
            Update
          </Button>
        </div>
      </form>

      {complete && (
        <p className="text-sm leading-5 mt-2 mb-0 text-green-700">
          The status has been updated successfully.
          <br />
          It will take a few moments for the table to update.
        </p>
      )}
    </>
  )
}

export default AccountToggle
