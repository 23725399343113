import React, { useEffect, useState } from "react"
import classnames from "classnames"
import ClipLoader from "react-spinners/ClipLoader"

import Table, { TableCell } from "../../table"
import getUserRecord from "../../../bssr-api/auth0/get-user"

function ReferenceTable({ references, token }) {
  const [selectedReferences, setReferences] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function getData() {
      if (references.length === 1) {
        const record = await getUserRecord(references[0].referee_id, token)
        return [
          {
            ...references[0],
            userId: record.data.user_id,
            family_name: record.data.family_name,
            given_name: record.data.given_name,
            picture: record.data.picture,
          },
        ]
      }

      if (references.length === 2) {
        const record_one = await getUserRecord(references[0].referee_id, token)
        const record_two = await getUserRecord(references[1].referee_id, token)

        return [
          {
            ...references[0],
            userId: record_one.data.user_id,
            family_name: record_one.data.family_name,
            given_name: record_one.data.given_name,
            picture: record_one.data.picture,
          },
          {
            ...references[1],
            userId: record_two.data.user_id,
            family_name: record_two.data.family_name,
            given_name: record_two.data.given_name,
            picture: record_two.data.picture,
          },
        ]
      }

      return []
    }

    if (references.length > 0) {
      getData().then((results) => {
        setReferences(results)
        setLoading(false)
      })
    }
  }, [references])

  if (loading)
    return (
      <div className="flex align-center justify-center bg-white py-6">
        <ClipLoader size={24} color="#3c366b" />
      </div>
    )

  return (
    <Table
      headers={[
        { label: "" },
        { label: "Referee Name" },
        { label: "Referral Status" },
      ]}
      rows={selectedReferences.map((user) => {
        return {
          cell: () => (
            <>
              <TableCell>
                <div className="flex items-center">
                  <div className="flex-shrink-0 h-10 w-10 lg:h-16 lg:w-16">
                    <img
                      className="h-10 w-10 lg:h-16 lg:w-16 rounded-full"
                      src={user.picture}
                      alt=""
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell>
                <div className="flex items-center">
                  <span className="text-sm leading-5 font-medium text-gray-900">
                    {user.given_name} {user.family_name}
                  </span>
                </div>
              </TableCell>
              <TableCell>
                <span
                  className={classnames(
                    "px-2 inline-flex text-xs leading-5 font-semibold rounded-full capitalize",
                    {
                      "bg-green-200 text-green-700": user.status === "approved",
                      "bg-red-200 text-red-700": user.status === "rejected",
                      "bg-yellow-400 text-yellow-800":
                        user.status === "pending",
                    }
                  )}
                >
                  {user.status}
                </span>
              </TableCell>
            </>
          ),
        }
      })}
    />
  )
}

export default ReferenceTable
