/**
 * Referees Section
 * This is the section that will let users allocate referees for their
 * membership application
 */

import React, { useState } from "react"
import { Link } from "gatsby"
import UserLookup from "../user-lookup"
import updateUserRecord from "../../bssr-api/auth0/update-user"
import getUserRecord from "../../bssr-api/auth0/get-user"
import ReferenceTable from "./references/ReferenceTable"
import sendReferenceEmail from "../../bssr-api/account/send-reference-email"

import { useAuth } from "../../providers/auth/AuthProvider"

function Referees({ token }) {
  const [loading, setLoading] = useState(false)
  const [state, dispatch] = useAuth()

  async function submitReferral(selectedUser) {
    const currentUserId = state.user_id
    const references = state.app_metadata.references_requested
    const requestedDate = new Date()

    setLoading(true)

    const payload = {
      app_metadata: {
        references_requested: [
          ...references,
          {
            referee_id: selectedUser.id,
            status: "pending", // one of ["pending", "approved", "declined"]
            testimonial: "",
            requested_at: requestedDate.toISOString(),
          },
        ],
        references_given: [],
      },
    }

    // Update current user with requested references
    const updatedUser = await updateUserRecord(
      currentUserId,
      payload,
      false,
      token
    )

    // Update the requested referee with a pending submission (submitted: false)
    const referee = await getUserRecord(selectedUser.id, token)

    await updateUserRecord(
      selectedUser.id,
      {
        app_metadata: {
          references_given: [
            ...referee.data.app_metadata.references_given,
            {
              requester_id: state.user_id,
              requester_name: state.name,
              requested_at: requestedDate.toISOString(),
              submitted: false,
              testimonial: "",
            },
          ],
        },
      },
      false,
      token
    )

    // Send reference email to the referee
    await sendReferenceEmail({
      referee: {
        name: selectedUser.name,
        email: selectedUser.email,
      },
      requester: {
        name: state.name,
      },
    })

    dispatch({ type: "setUser", payload: updatedUser.data })
    setLoading(false)
  }

  function renderReferees() {
    const requestedReferences = state.app_metadata.references_requested

    return (
      <>
        <div className="container mx-auto pt-4">
          <h3 className="text-2xl font-bold mb-3">Your Pending Referrals</h3>
          {state.app_metadata.account_type === "free" &&
            requestedReferences.filter((r) => r.status === "approved")
              .length === 2 && (
              <>
                <p className="mb-6">
                  Your references have been submitted and we are pleased to
                  welcome you to the BSSR.
                  <br />
                  Please complete your membership.
                </p>
                <Link
                  className="text-center block sm:inline-block bg-indigo-900 hover:bg-indigo-800 px-8 rounded focus:bg-blue-400 text-white font-semibold py-3"
                  to={`/register/payment?uid=${state.user_id.replace(
                    "auth0|",
                    ""
                  )}`}
                >
                  Complete your membership
                </Link>
              </>
            )}
          {requestedReferences.length !== 2 && (
            <>
              <p className="mb-6">
                Use the field below to search for the members that you would
                like to receive a referral from. We require at least two
                references before you can complete your membership.
              </p>

              <div className="w-3/4">
                <UserLookup
                  fieldKey="referee"
                  label="Search for Members"
                  onSubmit={(selectedUser) => submitReferral(selectedUser)}
                  value={null}
                  isLoading={loading}
                  references={requestedReferences}
                />
              </div>
            </>
          )}
        </div>

        {requestedReferences.length ? (
          <div className="container mx-auto mt-6">
            <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
              <ReferenceTable references={requestedReferences} token={token} />
            </div>
          </div>
        ) : null}
      </>
    )
  }

  return <section>{renderReferees()}</section>
}

export default Referees
