/**
 * Auth0 Get User
 * Get the user from the Auth0 Database
 */
import { navigate } from "gatsby"

async function findUsers(term, returnAll = false) {
  const response = await fetch(
    `/.netlify/functions/find-user?returnAll=${returnAll}&query=email:*${term}* or name:*${term}*`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )

  if (response.ok) {
    return await response.json()
  } else {
    if (response.status === 401) {
      console.log("session expired, logging out")
      navigate("/logout")
    }
  }
}

export default findUsers
