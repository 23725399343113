import * as React from "react"
import { Redirect } from "@reach/router"
import { useAuth0 } from "@auth0/auth0-react"

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuth0()

  return isAuthenticated ? (
    <Component {...rest} />
  ) : (
    <Redirect from="" to="/account" noThrow />
  )
}

export const PublicRoute = ({ component: Component, ...rest }) => (
  <Component {...rest} />
)
