import React from "react"
import format from "date-fns/format"
import { useAuth0 } from "@auth0/auth0-react"

import updateUserRecord from "../../bssr-api/auth0/update-user"
import { useAuth } from "../../providers/auth/AuthProvider"
import { openUploadWidget } from "../../utils/cloudinary-service"

export default function ProfileBanner({ profile, token }) {
  const [, dispatch] = useAuth()
  const { user } = useAuth0()
  const { role } = profile.app_metadata

  const beginUpload = (tag) => {
    const uploadOptions = {
      cloudName: "dnecdqjwp",
      tags: [tag],
      uploadPreset: "avatar-preset",
      sources: ["local", "url", "facebook", "google_drive"],
      maxFiles: 1,
      multiple: false,
      maxImageFileSize: 1500000,
      clientAllowedFormats: ["png", "jpeg", "jpg"],
      showCompletedButton: true,
      cropping: true,
    }

    openUploadWidget(uploadOptions, (error, result) => {
      if (!error) {
        if (result && result.event === "success") {
          const url = result.info.secure_url

          updateUserRecord(user.sub, { picture: url }, false, token)
            .then((res) => {
              dispatch({ type: "setUser", payload: res.data })
            })
            .catch((err) => {
              console.error("there was an err", err)
              alert(
                "There was a problem uploading the image, please try again."
              )
            })
        }
      } else {
        console.error("There was a problem uploading the image: ", error)
        alert("There was a problem uploading the image, please try again.")
      }
    })
  }

  return (
    <section
      id="personal-profile"
      className="w-full pb-32 bg-indigo-900 border-t-8 border-gray-400"
    >
      <div className="container mx-auto px-5 lg:px-16 mt-16 flex items-center">
        <figure
          className="relative cursor-pointer"
          onClick={() => beginUpload("image")}
        >
          {profile.app_metadata && !(role === "free" || role === "lapsed") && (
            <span className="absolute top-0 left-0 bg-green-600 text-white p-1 lg:p-3 rounded-full border-2 lg:border-4 border-indigo-900 z-10" />
          )}
          <div className="relative avatar-wrap h-16 w-16 lg:h-32 lg:w-32 mr-8 lg:mr-8 border-4 border-white rounded-full overflow-hidden">
            <img
              className="h-16 w-16 lg:h-32 lg:w-32"
              src={profile.picture}
              alt={profile.name}
            />
            <div className="avatar-wrap__overlay h-16 w-16 lg:h-32 lg:w-32">
              Upload Image
            </div>
          </div>
        </figure>

        <div className="w-full">
          <h2 className="serif font-bold text-gray-200 text-xl lg:text-4xl py-1 lg:py-3">
            {profile.given_name} {profile.family_name}
          </h2>
          {profile.app_metadata && !(role === "free" || role === "lapsed") && (
            <span className="inline-block bg-green-600 text-white text-xs tracking-wider md:text-sm px-2 rounded mr-4">
              Active
            </span>
          )}
          {profile.current_period_start && (
            <span className="inline-block text-gray-400">
              Member since{" "}
              {format(new Date(profile.current_period_start), "Mo MMMM yyyy")}
            </span>
          )}
        </div>
      </div>
    </section>
  )
}
