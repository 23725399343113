import React, { useState, useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { EmailNotice } from "../components/notice"
import { useAuth } from "../providers/auth/AuthProvider"
import {
  AccountDetailsForm,
  HospitalDetailsForm,
  ChangePasswordForm,
} from "../components/forms"

import ChangeEmailForm from "../components/forms/ChangeEmailForm"
import Navigation from "../components/account/pages/Navigation"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PageLoader from "../components/page-loader"
import ProfileBanner from "../components/profile-banner"
import getUserRecord from "../bssr-api/auth0/get-user"

export const Settings = () => {
  const { getIdTokenClaims } = useAuth0()
  const [state, dispatch] = useAuth()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getIdTokenClaims()
      .then((res) => {
        if (Object.keys(state).length === 0 && typeof window !== "undefined") {
          const idToken = res.__raw

          getUserRecord(res.sub, idToken)
            .then((res) => {
              if (res.data) {
                dispatch({ type: "setUser", payload: { ...res.data, idToken } })
                setLoading(false)
              } else {
                console.error("getUserData() issue with res", res)
              }
            })
            .catch((err) => {
              console.error("getUserData() error", err)
            })
        } else {
          setLoading(false)
        }
      })
      .catch((err) => console.error("err", err))
  }, [])

  if (loading) {
    return (
      <Layout>
        <Seo title="Your Profile" />
        <PageLoader />
      </Layout>
    )
  }

  return (
    <Layout>
      <Seo title="Account" />

      {typeof state.email_verified !== "undefined" &&
        state.email_verified === false && <EmailNotice user={state} />}

      <div className="container mx-auto px-1 py-3 lg:py-4 lg:flex items-start justify-end">
        <Navigation
          accountStatus={{
            type: state.app_metadata && state.app_metadata.account_type,
            role: state.app_metadata && state.app_metadata.role,
            is_overseas: state.app_metadata.is_overseas,
          }}
        />
      </div>

      <ProfileBanner profile={state} token={state.idToken} />

      <div className="container mx-auto -mt-16">
        <div className="max-w-5xl mx-auto bg-white shadow-xl px-5 py-3 pb-6 md:rounded-t-lg">
          <h3 className="uppercase font-bold tracking-wide text-gray-600 py-3 border-b-2 border-gray-400">
            Manage your profile
          </h3>

          <AccountDetailsForm data={state} token={state.idToken} />
          <HospitalDetailsForm />

          <h3 className="uppercase font-bold tracking-wide text-gray-600 py-3 border-b-2 border-gray-400">
            Manage your credentials
          </h3>

          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 md:pr-4">
              <ChangeEmailForm token={state.idToken} />
            </div>
            <div className="w-full md:w-1/2">
              <ChangePasswordForm email={state.email} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Settings
