/**
 * Send Lapsed Email
 */

async function sendReferralCompleteEmail({ member }) {
  try {
    const res = await fetch(
      "/.netlify/functions/send-referral-complete-email",
      {
        method: "POST",
        body: JSON.stringify({
          member: {
            name: member.name,
            email: member.email,
            given_name: member.given_name,
          },
        }),
      }
    )

    const json = await res.json()

    if (res.ok) {
      return json
    } else {
      throw new Error("There was a problem", res)
    }
  } catch (err) {
    throw new Error("Problem >>", err)
  }
}

export default sendReferralCompleteEmail
