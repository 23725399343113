import React, { useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"

import Table, { TableCell } from "../table"
import AccountToggle from "../auth0/AccountToggle"

const renderAccountType = (type) => {
  switch (type) {
    case "full":
      return "Full Member"
    case "retired":
      return "Retired/Honorary Member"
    case "associate":
      return "Associate Member"
    default:
      return "Administrator"
  }
}

export default function UserTable({ user, data, token }) {
  const [copiedValue, setCopiedValue] = useState()

  return (
    <Table
      headers={[
        { label: "Name" },
        { label: "Role" },
        { label: "Membership Status", hideOnSmall: true },
        { label: "" },
      ]}
      rows={data.map((node) => {
        return {
          cell: () => (
            <>
              <TableCell>
                <div className="flex items-center">
                  <div className="flex-shrink-0 h-10 w-10 lg:h-16 lg:w-16">
                    <img
                      className="h-10 w-10 lg:h-16 lg:w-16 rounded-full"
                      src={node.picture}
                      alt={node.name}
                    />
                  </div>
                  <div className="ml-4">
                    <div className="text-sm leading-5 font-medium text-gray-900">
                      {node.given_name} {node.family_name}
                    </div>
                    <div className="text-sm leading-5 text-gray-500">
                      {node.user_metadata?.hospital?.site}
                      {user.app_metadata &&
                        (user.app_metadata.role === "admin" ||
                          user.app_metadata.role === "exec") && (
                          <p>{node.email}</p>
                        )}
                    </div>
                  </div>
                </div>
              </TableCell>

              <TableCell>
                <div className="text-sm leading-5 text-gray-900 capitalize">
                  {node.role}
                </div>
              </TableCell>

              <TableCell>
                {user.app_metadata &&
                (user.app_metadata.role === "admin" ||
                  user.app_metadata.role === "exec") ? (
                  <AccountToggle
                    userId={node.user_id}
                    accountType={node.account_type}
                    token={token}
                  />
                ) : (
                  <span className="capitalize px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-200 text-green-700">
                    {renderAccountType(node.account_type)}
                  </span>
                )}
              </TableCell>
              <TableCell alignment="right">
                <CopyToClipboard
                  text={node.email}
                  onCopy={() => setCopiedValue(node.email)}
                >
                  <span className="cursor-pointer text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline text-sm font-medium">
                    {copiedValue === node.email ? "Copied!" : "Contact"}
                  </span>
                </CopyToClipboard>
              </TableCell>
            </>
          ),
        }
      })}
    />
  )
}
