import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

export default function TableHead({ headers, theme }) {
  return (
    <thead
      className={classnames({
        "bg-gray-200": theme === "dark",
        "bg-gray-50": theme === "light",
      })}
    >
      <tr>
        {headers.map((header, index) =>
          header.label.length ? (
            <th
              key={index}
              className="px-4 md:px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
            >
              <span
                className={classnames({
                  "md:inline hidden": header.hideOnSmall,
                })}
              >
                {header.label}
              </span>
            </th>
          ) : (
            <th
              className="table-cell px-6 py-3 border-b border-gray-200 bg-gray-50"
              key={index}
            />
          )
        )}
      </tr>
    </thead>
  )
}

TableHead.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      hideOnSmall: PropTypes.bool,
    })
  ),
  theme: PropTypes.string.isRequired,
}

TableHead.defaultProps = {
  headers: [],
}
