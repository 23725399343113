import React from "react"
import classnames from "classnames"
import TableHead from "./TableHead"
import TableBody from "./TableBody"
import PropTypes from "prop-types"

export default function Table({ rows, headers, theme }) {
  return (
    <table border="0" cellSpacing="0" cellPadding="0" className="min-w-full">
      <TableHead headers={headers} theme={theme} />
      <TableBody rows={rows} />
    </table>
  )
}

export function TableCell({ children, alignment }) {
  return (
    <td
      className={classnames(
        "px-4 md:px-6 py-4 whitespace-no-wrap border-b border-gray-200",
        {
          [`text-${alignment}`]: alignment,
        }
      )}
    >
      {children}
    </td>
  )
}

Table.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape()),
  theme: PropTypes.string,
}

Table.defaultProps = {
  headers: [],
  theme: "dark",
}

TableCell.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  alignment: PropTypes.string,
}

TableCell.defaultProps = {
  alignment: "left",
}
