/**
 * Send Reference Email
 */

async function sendReferenceEmail({ referee, requester }) {
  try {
    const res = await fetch("/.netlify/functions/send-referral-email", {
      method: "POST",
      body: JSON.stringify({
        referee: {
          name: referee.name,
          email: referee.email,
        },
        requester: {
          name: requester.name,
        },
      }),
    })

    const json = await res.json()

    if (res.ok) {
      return json
    } else {
      throw new Error("There was a problem", res)
    }
  } catch (err) {
    throw new Error("Problem >>", err)
  }
}

export default sendReferenceEmail
