/**
 * Management Page
 */

import React, { useState, useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { navigate } from "@reach/router"
import { useAuth } from "../providers/auth/AuthProvider"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageLoader from "../components/page-loader"
import getUserRecord from "../bssr-api/auth0/get-user"
import findUsers from "../bssr-api/auth0/find-users"
import updateUserRecord from "../bssr-api/auth0/update-user"

export const ManagementPage = () => {
  const { getIdTokenClaims } = useAuth0()
  const [state, dispatch] = useAuth()
  const [loading, setLoading] = useState(true)
  const [term, setTerm] = useState()
  const [results, setResults] = useState([])
  const [searching, setSearching] = useState(false)

  useEffect(() => {
    getIdTokenClaims()
      .then((res) => {
        if (Object.keys(state).length === 0 && typeof window !== "undefined") {
          const idToken = res.__raw

          getUserRecord(res.sub, idToken)
            .then((res) => {
              if (res.data) {
                dispatch({ type: "setUser", payload: { ...res.data, idToken } })
                setLoading(false)
              } else {
                console.error("getUserData() issue with res", res)
              }
            })
            .catch((err) => {
              console.error("getUserData() error", err)
            })
        } else {
          setLoading(false)
        }
      })
      .catch((err) => console.error("err", err))
  }, [])

  async function findUser(event) {
    setSearching(true)

    if (event) event.preventDefault()

    const response = await findUsers(term, true)

    setResults(response.data)

    setSearching(false)
  }

  async function approveOverseasUser(userId) {
    const response = await updateUserRecord(
      userId,
      { app_metadata: { overseas_user_approved: true } },
      false,
      state.idToken
    )

    if (response.data) {
      alert("The user was successfully approved")
      await findUser()
    } else {
      alert("There was a problem approving the user. Please try again")
      console.error("approveOverseasUser() error", response)
    }
  }

  if (loading) {
    return (
      <Layout>
        <SEO title="Your Profile" />
        <PageLoader />
      </Layout>
    )
  }

  if (state?.app_metadata?.role !== "admin") {
    return navigate("/account")
  }

  return (
    <Layout>
      <SEO title="Management" />

      <div>
        <section
          id="search-members"
          className="w-full pb-8 bg-indigo-900 border-t-8 border-gray-400 mb-12"
        >
          <form
            className="max-w-2xl mx-auto py-3 px-5 mt-8"
            onSubmit={findUser}
          >
            <label
              htmlFor="search_term"
              className="block text-center md:text-lg lg:text-2xl ml-2 font-medium text-gray-700"
            >
              <h2 className="max-w-2xl serif text-gray-200 text-xl lg:text-5xl font-bold mt-3">
                Manage Members
              </h2>
              <span className="block text-sm font-normal text-indigo-200 pt-1">
                Search for members by their name or email address
              </span>
            </label>

            <div className="flex justify-center items-center gap-4 mt-8">
              <input
                type="text"
                id="term"
                value={term}
                onChange={(e) => setTerm(e.target.value)}
                placeholder="Who do you want to find?"
                className="w-full px-4 py-3 rounded-lg bg-gray-200 border-2 focus:border-blue-500 focus:bg-white focus:outline-none"
                required
              />

              <button
                type="submit"
                className="bg-white text-indigo-900 font-semibold px-5 py-2 rounded-lg h-full"
              >
                {searching ? "Loading..." : "Search"}
              </button>
            </div>
          </form>
        </section>

        <div className="mt-4 container mx-auto space-y-8">
          {results.length > 0 &&
            results.map((user) => (
              <div
                key={user.user_id}
                className="bg-gray-100 border-b border-gray-300 p-6 rounded-lg shadow-sm relative"
              >
                <div className="flex items-center gap-5">
                  <img
                    src={user.picture}
                    alt="Profile image"
                    className="w-12 h-12 rounded-full"
                  />

                  <div>
                    <h3 className="text-xl font-bold">
                      {user.given_name} {user.family_name}
                    </h3>
                    <p>{user.email}</p>
                  </div>
                </div>

                {user.is_overseas ? (
                  <div className="absolute right-4 top-4 text-sm">
                    <p className="bg-yellow-400 text-black font-semibold px-4 py-1 rounded-full">
                      {user.overseas_user_approved
                        ? "Overseas User"
                        : "Awaiting Overseas Approval"}
                    </p>
                  </div>
                ) : (
                  <div className="absolute right-4 top-4 text-sm">
                    <p className="bg-indigo-400 text-black font-semibold px-4 py-1 rounded-full">
                      UK User
                    </p>
                  </div>
                )}

                <details className="mt-3 pb-4 border-b border-gray-300">
                  <summary className="cursor-pointer">View Auth0 Data</summary>

                  <pre className="bg-white text-black p-6 rounded-lg mt-4 shadow-sm">
                    {JSON.stringify(user, null, 2)}
                  </pre>
                </details>

                {user.is_overseas && !user.overseas_user_approved && (
                  <div className="mt-5">
                    <button
                      onClick={() => approveOverseasUser(user.user_id)}
                      type="button"
                      className="bg-indigo-900 text-white font-semibold px-3 py-1 rounded-lg"
                    >
                      Approve Overseas Application
                    </button>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </Layout>
  )
}
