import React, { useState } from "react"
import * as Yup from "yup"
import { useAuth0 } from "@auth0/auth0-react"
import { useFormik } from "formik"
import { Button } from "../button"
import { TextField } from "../inputs"
import { useAuth } from "../../providers/auth/AuthProvider"
import { isBrowser } from "../../utils/auth"

import updateUserRecord from "../../bssr-api/auth0/update-user"

function ChangeEmailForm({ token }) {
  const { logout } = useAuth0()
  const [state, dispatch] = useAuth()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  const accountDetailsSchema = Yup.object().shape({
    email: Yup.string()
      .email("Oops. The email address doesn't look quite right.")
      .required("This is a required field"),
  })

  const handleLogout = () => {
    if (isBrowser) {
      localStorage.setItem("isLoggedIn", false)
    }

    logout()
  }

  const formik = useFormik({
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      email: state.email,
    },
    onSubmit: async (values) => {
      setLoading(true)

      if (errorMessage) {
        setErrorMessage()
      }

      const { email } = values
      const res = await updateUserRecord(
        state.user_id,
        {
          email,
          verify_email: state.email_verified === false,
          email_verified: state.email_verified,
        },
        false,
        token
      )

      if (res.error) {
        setErrorMessage(res.error.message)
        setLoading(false)
      } else {
        dispatch({ type: "setUser", payload: res.data })

        setTimeout(() => {
          handleLogout()
        }, 1000)
      }
    },
    validationSchema: accountDetailsSchema,
  })

  const errors = formik.errors

  return (
    <form
      className="flex flex-col justify-between"
      onSubmit={formik.handleSubmit}
    >
      <div>
        <h3 className="font-bold text-lg lg:text-xl text-gray-800 py-3 mt-4">
          Change Email Address
        </h3>

        <p className="mb-6">
          As a security measure, you will be logged out of your account after
          changing your email address. You will then need to log back in with
          your new email address.
        </p>
      </div>

      {errorMessage && (
        <p className="text-red-600 font-bold mb-2">
          There was a problem: {errorMessage}.
        </p>
      )}

      <div className="flex">
        <div className="flex-1">
          <TextField
            id="email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            error={errors.email}
            noSpacing
          />
        </div>
        <Button
          className="ml-3"
          type="submit"
          isLoading={loading}
          type="submit"
        >
          Update
        </Button>
      </div>
    </form>
  )
}

export default ChangeEmailForm
