import React, { useState } from "react"
import { useFormik } from "formik"

import { useAuth } from "../../../providers/auth/AuthProvider"
import getUserRecord from "../../../bssr-api/auth0/get-user"
import updateUserRecord from "../../../bssr-api/auth0/update-user"
import Select from "../../inputs/select"

import { Button, PositiveButton } from "../../button"
import sendReferralCompleteEmail from "../../../bssr-api/account/send-referral-complete-email"

function ReferenceForm({ reference, cancelRequest, token }) {
  const [state, dispatch] = useAuth()
  const [isLoading, setIsLoading] = useState(false)

  // Setup formik
  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      testimonial: "",
      status: "approved",
    },

    onSubmit: async (values) => {
      setIsLoading(true)
      const approvedDate = new Date()
      const { testimonial, status } = values

      const payload = {
        app_metadata: {
          references_given: state.app_metadata.references_given.map((ref) => {
            if (ref.requester_id === reference.requester_id) {
              return {
                ...reference,
                submitted: true,
                responded_at: approvedDate.toISOString(),
                testimonial,
                status,
              }
            }

            return ref
          }),
        },
      }

      // Then update current user with their submitted reference
      const updatedUser = await updateUserRecord(
        state.user_id,
        payload,
        false,
        token
      )

      // Update reducer
      dispatch({ type: "setUser", payload: updatedUser.data })

      // Requester (person requesting referral)
      try {
        const requester = await getUserRecord(reference.requester_id, token)
        try {
          const requesterPayload = {
            app_metadata: {
              references_requested:
                requester.data.app_metadata.references_requested.map((ref) => {
                  if (ref.referee_id === state.user_id) {
                    return {
                      ...ref,
                      status,
                      testimonial,
                      responded_at: approvedDate.toISOString(),
                    }
                  }
                  return ref
                }),
            },
          }

          // Add request to the reference log against the
          // user meta data
          const newUser = await updateUserRecord(
            requester.data.user_id,
            requesterPayload,
            false,
            token
          )

          const approvedReferences =
            newUser.data.app_metadata.references_requested.filter((ref) => {
              return ref.status === "approved"
            })

          if (approvedReferences.length === 2) {
            // Send the email here
            await sendReferralCompleteEmail({
              member: {
                name: newUser.data.name,
                email: newUser.data.email,
                given_name: newUser.data.given_name,
              },
            })
          }

          cancelRequest()
          setIsLoading(false)
        } catch (err) {
          console.log("there was an err", err)
          alert("There was a problem submitting this request.")
        }
      } catch (err) {
        console.log("there was an err", err)
        alert("There was a problem submitting this request.")
      }
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <p>
        Please provide a reference for <b>{reference.requester_name}</b>
      </p>

      <label htmlFor="status" className="block my-4">
        Are you happy to welcome <b>{reference.requester_name}</b> to the BSSR?
      </label>
      <Select
        id="status"
        name="status"
        onChange={formik.handleChange}
        onBlur={formik.handleChange}
        value={formik.values.status}
      >
        <option value="approved">Yes</option>
        <option value="declined">No</option>
      </Select>

      <label htmlFor="testimonial" className="block my-4">
        Please write a brief testimonial
      </label>
      <textarea
        rows="10"
        cols="60"
        id="testimonial"
        name="testimonial"
        className="w-full px-4 py-3 rounded-lg bg-gray-200 border focus:border-blue-500 focus:bg-white focus:outline-none"
        onChange={formik.handleChange}
        value={formik.values.testimonial}
      />

      <div className="flex justify-end">
        <Button
          type="button"
          className="px-6 mt-4 mr-4"
          onClick={cancelRequest}
        >
          Go Back To Referrals
        </Button>
        <PositiveButton
          type="submit"
          className="px-6 mt-4"
          isLoading={isLoading}
        >
          Submit Referral
        </PositiveButton>
      </div>
    </form>
  )
}

export default ReferenceForm
