import React from "react"

/**
 * Lookup results component
 */
function Results({ onSelect, results }) {
  return (
    <div className="bg-white rounded-lg shadow-md mb-6">
      <div className="flex items-center">
        {results.length > 0 ? (
          <ul className="m-0 flex-1">
            {results.map(user => (
              <li key={user.id} className="hover:bg-gray-200">
                <span
                  className="flex p-4 mt-0 rounded"
                  role="button"
                  tabIndex={0}
                  onClick={() => onSelect(user)}
                  onKeyDown={event => {
                    if (event.keyCode === 13) {
                      onSelect(user)
                    }
                  }}
                >
                  {user.name}
                  <br />
                  {user.email}
                </span>
              </li>
            ))}
          </ul>
        ) : (
          <p className="p-4 m-0">No results found</p>
        )}
      </div>
    </div>
  )
}

export default Results
