import React, { useState } from "react"
import { useAuth } from "../../providers/auth/AuthProvider"
import ReferenceForm from "./references/ReferenceForm"
import Table, { TableCell } from "../table"

function RequestedReferences({ token }) {
  const [state] = useAuth()
  const [activeIndex, setActiveIndex] = useState(undefined)

  const { references_given } = state.app_metadata

  return (
    <div>
      <h3 className="uppercase font-bold tracking-wide text-gray-600 py-3 mt-4 mb-4 border-b-2 border-gray-400">
        Your Reference Requests
      </h3>
      {references_given?.length > 0 ? (
        <>
          {typeof activeIndex !== "undefined" ? (
            <ReferenceForm
              reference={references_given[activeIndex]}
              cancelRequest={() => setActiveIndex(undefined)}
              token={token}
            />
          ) : (
            <>
              <p className="mb-4">
                The following prospective members have requested a reference
                from you in order to join the BSSR.
              </p>
              <div className="align-middle block min-w-full shadow sm:rounded-lg border-b border-gray-200 mb-4 w-full">
                <div className="overflow-x-auto">
                  <Table
                    theme="light"
                    headers={[
                      { label: "Requester Name" },
                      { label: "Request Status" },
                      { label: "" },
                    ]}
                    rows={references_given.map((reference, index) => {
                      return {
                        cell: () => (
                          <>
                            <TableCell>
                              <div className="flex items-center text-sm">
                                {reference.requester_name}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="flex items-center text-sm">
                                {reference.submitted
                                  ? "Submitted"
                                  : "Outstanding"}
                              </div>
                            </TableCell>
                            <TableCell>
                              {!reference.submitted && (
                                <span
                                  className="text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline text-sm font-medium"
                                  role="button"
                                  onClick={() => setActiveIndex(index)}
                                >
                                  Complete Reference
                                </span>
                              )}
                            </TableCell>
                          </>
                        ),
                      }
                    })}
                  />
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <p className="italic">You currently have no reference requests.</p>
      )}
    </div>
  )
}

export default RequestedReferences
